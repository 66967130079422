var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        "hide-actions": "",
        title: "Roles and Permssions",
        icon: "mdi-account-check",
        "btn-label": "Save Permissions",
        "btn-icon": "mdi-account-check"
      }
    },
    [
      _c("h3", [_vm._v("Roles")]),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.user.roles, function(ref, index) {
            var name = ref.name
            return [
              _c(
                "v-list-item",
                { key: index },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "font-weight-bold text-h6" },
                    [_vm._v(_vm._s(name))]
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c("h3", [_vm._v("Permissions")]),
      _c(
        "v-list",
        [
          _vm._l(_vm.user.permission_names, function(permission, index) {
            return [
              _c(
                "v-list-item",
                { key: index, staticClass: "my-n2" },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { staticClass: "primary--text" }, [
                        _vm._v("mdi-account-key")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-title", { staticClass: "primary--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$_.titleCase(
                            permission
                              .replace(/\-/g, " ")
                              .split(".")
                              .reverse()
                              .join(" ")
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              index < _vm.user.permission_names.length - 1
                ? _c("v-divider", { key: "divider-" + index })
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }