<template>
    <psi-form-layout-section
        hide-actions
        title="Roles and Permssions"
        icon="mdi-account-check"
        btn-label="Save Permissions"
        btn-icon="mdi-account-check"
    >
        <h3>Roles</h3>
        <v-list dense>
            <template v-for="({ name }, index) in user.roles">
                <v-list-item :key="index">
                    <v-list-item-content class="font-weight-bold text-h6">{{
                        name
                    }}</v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <h3>Permissions</h3>
        <v-list>
            <template v-for="(permission, index) in user.permission_names">
                <v-list-item :key="index" class="my-n2">
                    <v-list-item-icon>
                        <v-icon class="primary--text">mdi-account-key</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="primary--text">
                        {{
                            $_.titleCase(
                                permission
                                    .replace(/\-/g, " ")
                                    .split(".")
                                    .reverse()
                                    .join(" ")
                            )
                        }}
                    </v-list-item-title>
                </v-list-item>
                <v-divider
                    :key="`divider-${index}`"
                    v-if="index < user.permission_names.length - 1"
                ></v-divider>
            </template>
        </v-list>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "role-permissions",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        data() {
            return this.users;
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update() {},
    },
};
</script>

<style scoped>
</style>